
export default {
  computed: {
    url() {
      const number = this.$contact.mobile
      return `tel:${String(number).replace(' ', '')}`
    }
  },
  methods: {
    onClick() {
      const data = { category: 'cta', label: 'click' }
      this.$tracker.trackEvent('call_button', data)
    }
  }
}
