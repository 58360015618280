export default async (ctx, inject) => {
  const options = {"el":{"icons":true,"email":"info@noloss.gr","address":"Δέγλερη 2, Αθήνα 115 22","geoAddress":"Δέγλερη 2, Αθήνα 115 22","phone":"+30 210 090 0923","mobile":"+30 6948 465 878","businessHours":[{"days":"Δευτέρα - Παρασκευή","hours":"09:00 - 16:30"}]}}
  const attrs = Object.values(options)[0]

  const contact = {
    ...options,
    get details() {
      return this[ctx.app.i18n.vm.locale] || {}
    }
  }

  Object.keys(attrs).forEach(key => {
    Object.defineProperty(contact, key, {
      get() {
        return this.details[key]
      }
    })
  })

  inject('contact', contact)
}
