
export default {
  vessel: {
    title: {
      lineHeight: 'sm',
      fontSize: 'md',
      font: 'sans',
      fontWeight: 'semibold'
    },
    link: {
      display: 'inline',
      fontWeight: 'semibold',
      textDecoration: 'underline',
      fontSize: 'sm',
      color: 'primary'
    }
  },
  async fetch() {
    this.questions = await this.$content('questions')
      .only(['title', 'shortDesc', 'slug'])
      .sortBy('slug', 'ASC')
      .fetch()
  },
  data() {
    return {
      questions: [],
      active: null
    }
  },
  methods: {
    detailsLink({ slug }) {
      const path = this.$pagePath('syxnes-erotiseis')
      const query = { question: slug }

      return { path, query }
    }
  }
}
