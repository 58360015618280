
export default {
  async fetch() {
    this.page = await this.$content('pages/actions')
      .only(['title', 'shortDesc'])
      .fetch()
  },
  data() {
    return {
      page: null
    }
  },
}
