export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const CallButton = () => import('../../components/CallButton.vue' /* webpackChunkName: "components/call-button" */).then(c => wrapFunctional(c.default || c))
export const ContentBox = () => import('../../components/ContentBox.vue' /* webpackChunkName: "components/content-box" */).then(c => wrapFunctional(c.default || c))
export const CustomContactForm = () => import('../../components/CustomContactForm.vue' /* webpackChunkName: "components/custom-contact-form" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const SocialAccounts = () => import('../../components/SocialAccounts.vue' /* webpackChunkName: "components/social-accounts" */).then(c => wrapFunctional(c.default || c))
export const ActionsCarousel = () => import('../../components/actions/ActionsCarousel.vue' /* webpackChunkName: "components/actions-carousel" */).then(c => wrapFunctional(c.default || c))
export const ActionsList = () => import('../../components/actions/ActionsList.vue' /* webpackChunkName: "components/actions-list" */).then(c => wrapFunctional(c.default || c))
export const CasesBoxSlider = () => import('../../components/cases/CasesBoxSlider.vue' /* webpackChunkName: "components/cases-box-slider" */).then(c => wrapFunctional(c.default || c))
export const CasesList = () => import('../../components/cases/CasesList.vue' /* webpackChunkName: "components/cases-list" */).then(c => wrapFunctional(c.default || c))
export const CasesSlider = () => import('../../components/cases/CasesSlider.vue' /* webpackChunkName: "components/cases-slider" */).then(c => wrapFunctional(c.default || c))
export const ContentLayout = () => import('../../components/layout/ContentLayout.vue' /* webpackChunkName: "components/content-layout" */).then(c => wrapFunctional(c.default || c))
export const Copyright = () => import('../../components/layout/Copyright.vue' /* webpackChunkName: "components/copyright" */).then(c => wrapFunctional(c.default || c))
export const FooterColumn = () => import('../../components/layout/FooterColumn.vue' /* webpackChunkName: "components/footer-column" */).then(c => wrapFunctional(c.default || c))
export const FooterColumns = () => import('../../components/layout/FooterColumns.vue' /* webpackChunkName: "components/footer-columns" */).then(c => wrapFunctional(c.default || c))
export const HomeHeader = () => import('../../components/layout/HomeHeader.vue' /* webpackChunkName: "components/home-header" */).then(c => wrapFunctional(c.default || c))
export const Layout = () => import('../../components/layout/Layout.vue' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c))
export const MenuActions = () => import('../../components/layout/MenuActions.vue' /* webpackChunkName: "components/menu-actions" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/layout/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const PracticeAreasList = () => import('../../components/practice-areas/PracticeAreasList.vue' /* webpackChunkName: "components/practice-areas-list" */).then(c => wrapFunctional(c.default || c))
export const FaqAccordion = () => import('../../components/questions/FaqAccordion.vue' /* webpackChunkName: "components/faq-accordion" */).then(c => wrapFunctional(c.default || c))
export const FaqList = () => import('../../components/questions/FaqList.vue' /* webpackChunkName: "components/faq-list" */).then(c => wrapFunctional(c.default || c))
export const ActionsSection = () => import('../../components/sections/ActionsSection.vue' /* webpackChunkName: "components/actions-section" */).then(c => wrapFunctional(c.default || c))
export const ContactSection = () => import('../../components/sections/ContactSection.vue' /* webpackChunkName: "components/contact-section" */).then(c => wrapFunctional(c.default || c))
export const HighlightsSection = () => import('../../components/sections/HighlightsSection.vue' /* webpackChunkName: "components/highlights-section" */).then(c => wrapFunctional(c.default || c))
export const HomeContent = () => import('../../components/sections/HomeContent.vue' /* webpackChunkName: "components/home-content" */).then(c => wrapFunctional(c.default || c))
export const PaymentSection = () => import('../../components/sections/PaymentSection.vue' /* webpackChunkName: "components/payment-section" */).then(c => wrapFunctional(c.default || c))
export const PracticeAreas = () => import('../../components/sections/PracticeAreas.vue' /* webpackChunkName: "components/practice-areas" */).then(c => wrapFunctional(c.default || c))
export const TeamSection = () => import('../../components/sections/TeamSection.vue' /* webpackChunkName: "components/team-section" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
