module.exports = {
  theme: {
    colors: {
      primary: '#0b2a4a',
      black: '#352f30',
      success: '#26b65a',
      gold: '#d49c35'
    },
    fonts: {
      sans: [
        'Source Sans Pro',
        'Arial',
        'sans-serif'
      ],
      serif: [
        'Source Serif Pro',
        'Arial',
        'sans-serif'
      ]
    },
    typography: {
      body: {
        font: 'sans',
        leading: 'md',
        color: 'black',
        weight: 'light',
        size: 'md'
      },
      title: {
        font: 'serif',
        leading: 'sm',
        weight: 'bold'
      },
      paragraph: {
        leading: 'lg',
        weight: 'light'
      },
      link: {
        color: 'primary'
      }
    }
  }
}
