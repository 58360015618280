
export default {
  data() {
    return {
      involvedIn: null,
      claim: null,
    }
  },
  computed: {
    meta() {
      return {
        [this.$t('involvedIn')]: this.involvedIn,
        [this.$t('claim')]: this.claim,
      }
    },
    involvedOptions() {
      return [
        {
          label: this.$t('site.contactForm.fatalCarAccident'),
          value: this.$t('site.contactForm.fatalCarAccident')
        },
        {
          label: this.$t('site.contactForm.motorcycleAccident'),
          value: this.$t('site.contactForm.motorcycleAccident')
        },
        {
          label: this.$t('site.contactForm.carAccidentWithInjury'),
          value: this.$t('site.contactForm.carAccidentWithInjury')
        },
      ]
    },
    claimOptions() {
      return [
        {
          label: this.$t('site.contactForm.compensationFatalCarAccident'),
          value: this.$t('site.contactForm.compensationFatalCarAccident')
        },
        {
          label: this.$t('site.contactForm.compensationInjuryFromCarAccident'),
          value: this.$t('site.contactForm.compensationInjuryFromCarAccident')
        },
        {
          label: this.$t('site.contactForm.compensationNonPecuniaryDamage'),
          value: this.$t('site.contactForm.compensationNonPecuniaryDamage')
        },
        {
          label: this.$t('site.contactForm.compensationMotorcycleAccident'),
          value: this.$t('site.contactForm.compensationMotorcycleAccident')
        },
      ]
    },
    inputs() {
      return [
        {
          $type: 'input',
          $id: 'name',
          $prop: 'name',
          label: this.$t('site.contactForm.name'),
          $attrs: {
            span: 2,
          },
          rules: [{
            required: true,
            message: this.$t('site.validations.required'),
            trigger: 'change'
          }]
        },
        {
          $type: 'select',
          $id: 'involvedIn',
          $prop: 'involvedIn',
          label: this.$t('site.contactForm.involvedIn'),
          $options: this.involvedOptions,
          $attrs: {
            span: 2,
          },
          on: {
            input: (value) => {
              if (value) {
                this.involvedIn = value
              }
            }
          }
        },
        {
          $type: 'select',
          $id: 'claim',
          $prop: 'claim',
          label: this.$t('site.contactForm.claim'),
          $options: this.claimOptions,
          $attrs: {
            span: 2,
          },
          on: {
            input: (value) => {
              if (value) {
                this.claim = value
              }
            }
          }
        },
        {
          $type: 'input',
          $id: 'email',
          $prop: 'email',
          label: this.$t('site.contactForm.email'),
          $attrs: {
            span: { all: 2, lg: 1 },
          },
          $el: {
            type: 'email'
          },
          rules: [{
            required: true,
            message: this.$t('site.validations.required'),
            trigger: 'change'
          },
          {
            type: 'email',
            message: this.$t('site.validations.email'),
            trigger: 'change'
          }]
        },
        {
          $type: 'input',
          $id: 'phone',
          $prop: 'phone',
          label: this.$t('site.contactForm.phone'),
          $attrs: {
            span: { all: 2, lg: 1 },
          },
          $el: {
            type: 'tel'
          }
        },
        {
          $type: 'input',
          $id: 'message',
          $prop: 'message',
          label: this.$t('site.contactForm.message'),
          $attrs: {
            span: 2
          },
          $el: {
            type: 'textarea',
            autosize: { minRows: 2, maxRows: 4 }
          },
          rules: [{
            required: true,
            message: this.$t('site.validations.required'),
            trigger: 'change'
          }]
        }
      ]
    }
  }
}
