import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _47af726e = () => interopDefault(import('../pages/amoivi-tropoi-xrimatodotisis.vue' /* webpackChunkName: "pages/amoivi-tropoi-xrimatodotisis" */))
const _69c9604b = () => interopDefault(import('../node_modules/@evince/site/lib/pages/cookie-policy.vue' /* webpackChunkName: "pages/site/cookie-policy" */))
const _4815401f = () => interopDefault(import('../pages/energeies-meta-apo-troxaio.vue' /* webpackChunkName: "pages/energeies-meta-apo-troxaio" */))
const _77f43e64 = () => interopDefault(import('../pages/epikoinonia.vue' /* webpackChunkName: "pages/epikoinonia" */))
const _3cb5e3d3 = () => interopDefault(import('../pages/sxetika-me-emas.vue' /* webpackChunkName: "pages/sxetika-me-emas" */))
const _a5e7538c = () => interopDefault(import('../pages/syxnes-erotiseis.vue' /* webpackChunkName: "pages/syxnes-erotiseis" */))
const _b7bf1422 = () => interopDefault(import('../pages/ypiresies.vue' /* webpackChunkName: "pages/ypiresies" */))
const _0b328ac2 = () => interopDefault(import('../pages/ypotheseis.vue' /* webpackChunkName: "pages/ypotheseis" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7d1593c2 = () => interopDefault(import('../node_modules/@evince/site/lib/pages/request.vue' /* webpackChunkName: "pages/site/request" */))
const _13c3d3d2 = () => interopDefault(import('../pages/tomeis/_id.vue' /* webpackChunkName: "pages/tomeis/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/amoivi-tropoi-xrimatodotisis",
    component: _47af726e,
    name: "amoivi-tropoi-xrimatodotisis___el"
  }, {
    path: "/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___el"
  }, {
    path: "/energeies-meta-apo-troxaio",
    component: _4815401f,
    name: "energeies-meta-apo-troxaio___el"
  }, {
    path: "/epikoinonia",
    component: _77f43e64,
    name: "epikoinonia___el"
  }, {
    path: "/sxetika-me-emas",
    component: _3cb5e3d3,
    name: "sxetika-me-emas___el"
  }, {
    path: "/syxnes-erotiseis",
    component: _a5e7538c,
    name: "syxnes-erotiseis___el"
  }, {
    path: "/ypiresies",
    component: _b7bf1422,
    name: "ypiresies___el"
  }, {
    path: "/ypotheseis",
    component: _0b328ac2,
    name: "ypotheseis___el"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___el"
  }, {
    path: "/request/:id?",
    component: _7d1593c2,
    name: "request-id___el"
  }, {
    path: "/tomeis/:id?",
    component: _13c3d3d2,
    name: "tomeis-id___el"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
