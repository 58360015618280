import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=59d34f66"
import script from "./TopBar.vue?vue&type=script&lang=js"
export * from "./TopBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/opt/build/repo/components/Logo.vue').default,ScMainNav: require('/opt/build/repo/node_modules/@evince/site/lib/packages/MainNav.vue').default,CallButton: require('/opt/build/repo/components/CallButton.vue').default,ScMobileMenu: require('/opt/build/repo/node_modules/@evince/site/lib/packages/MobileMenu.vue').default})
