
export default {
  props: {
    stuck: {
      type: Boolean,
      default: false
    }
  },
  async fetch() {
    this.areas = await this.$content('practice-areas')
      .only(['slug', 'title'])
      .sortBy('createdAt', 'ASC')
      .fetch()
  },
  data() {
    return {
      areas: []
    }
  },
  computed: {
    links() {
      return [
        { label: 'pages.home', route: '/' },
        { label: 'pages.practiceAreas', route: 'tomeis', children: this.practiceAreas },
        { label: 'pages.services', route: '/ypiresies' },
        { label: 'pages.about', route: 'sxetika-me-emas', children: [
          { route: '/sxetika-me-emas', label: this.$t('pages.about') },
          { route: '/ypotheseis', label: this.$t('pages.cases') },
          { route: '/amoivi-tropoi-xrimatodotisis', label: this.$t('pages.payment') },
          { route: '/energeies-meta-apo-troxaio', label: this.$t('pages.actions') },
        ] },
        { label: 'pages.contact', route: '/epikoinonia' }
      ]
    },
    practiceAreas() {
      const items = []

      this.areas.forEach(({ slug, title }) => {
        const item = {
          route: this.$pagePath({
            name: 'tomeis-id',
            params: { id: slug }
          }),
          label: title,
        }

        items.push(item)
      })

      return items
    },
  }
}
