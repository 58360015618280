
export default {
  inheritAttrs: false,
  vessel: {
    box: {
      backgroundColor: 'white',
      shadow: 'lg',
      padding: 'md',
      md: {
        padding: 'lg'
      }
    }
  }
}
