import { render, staticRenderFns } from "./ContactSection.vue?vue&type=template&id=7a3a0234"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FaqAccordion: require('/opt/build/repo/components/questions/FaqAccordion.vue').default,CustomContactForm: require('/opt/build/repo/components/CustomContactForm.vue').default,ContentBox: require('/opt/build/repo/components/ContentBox.vue').default})
