
export default {
  head() {
    return {
      titleTemplate(title) {
        return title ? `${title} | ${this.$t('siteName')}` : this.$t('siteName')
      }
    }
  },
  vessel: {
    topbar: {
      paddingX: 'sm',
      paddingY: 'sm',
      $stuck: {
        backgroundColor(value) {
          return value ? 'white': 'transparent'
        }
      }
    },
    footer: {
      overflow: 'hidden'
    }
  },
  data() {
    return {
      stuck: false,
      sticky: true
    }
  },
  methods: {
    onStick() {
      this.stuck = true
    },
    onUnstick() {
      this.stuck = false
    }
  }
}
