
export default {
  vessel: {
    box: {
      textAlign: 'left',
      padding: 'md',
      xl: {
        padding: 'lg'
      }
    },
    blueBox: {
      $extend: 'box',
      backgroundColor: 'primary',
      color: 'white',
      textAlign: 'center',
      lg: {
        textAlign: 'left'
      }
    },
    grayBox: {
      $extend: 'box',
      backgroundColor: 'grey-lighter',
      position: 'relative'
    },
    buttons: {
      lg: {
        position: 'absolute',
        pinRight: true,
        padding: 'sm',
        pinBottom: true
      }
    },
    step: {
      borderBottomWidth: '4',
      cursor: 'pointer',
      textAlign: 'left',
      padding: 'xs',
      lg: {
        paddingLeft: 'sm',
        borderLeftWidth: 4,
        borderBottomWidth: 0
      }
    }
  },
  async fetch() {
    this.actions = await this.$content('actions')
      .only(['title', 'body'])
      .sortBy('slug', 'ASC')
      .fetch()

    this.page = await this.$content('pages/actions')
      .only(['cta'])
      .fetch()
  },
  data() {
    return {
      actions: [],
      page: null
    }
  },
  computed: {
    buttonAttrs() {
      return {
        textAlign: 'center',
        lineHeight: 'sm',
        paddingX: '2xs'
      }
    }
  }
}
