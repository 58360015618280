
export default {
  vessel: {
    section: {
      position: 'relative'
    },
    social: {
      fontSize: 'md',
      marginTop: 'lg',
      negativeMarginLeft: 'xs'
    }
  },
  async fetch() {
    this.practiceAreas = await this.$content('practice-areas')
      .only(['slug', 'title'])
      .sortBy('createdAt', 'ASC')
      .fetch()
  },
  data() {
    return {
      practiceAreas: [],
      quickLinks: [
        {
          path: 'ypiresies',
          label: 'pages.services',
          translate: true
        },
        {
          path: 'syxnes-erotiseis',
          label: 'pages.faq',
          translate: true
        },
        {
          path: 'sxetika-me-emas',
          label: 'pages.about',
          translate: true
        },
        {
          path: 'amoivi-tropoi-xrimatodotisis',
          label: 'pages.payment',
          translate: true
        },
        {
          path: 'cookie-policy',
          label: 'site.cookiePolicy',
          translate: true
        }
      ],
      socialAccounts: [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/pages/'
        },
        {
          name: 'Instagram',
          icon: 'instagram',
          url: 'https://www.instagram.com/'
        }
      ]
    }
  },
  computed: {
    areas() {
      const items = []

      this.practiceAreas.forEach(({ slug, title }) => {
        const item = {
          path: {
            name: 'tomeis-id',
            params: { id: slug }
          },
          label: title,
          translate: false
        }

        items.push(item)
      })

      return items
    }
  }
}
